<template>
  <div id="contact">
    <div class="contact_content">
      
      <img
        src="../assets/image/bg7.png"
        alt=""
      >
      <!-- <img
        src="../assets/image/bn3.jpg"
        alt=""
      > -->
      <div class="contact_box">
        <div class="box">

          <span class="title">联系人</span>
          <p>
            <img
              src="../assets/image/contacts.png"
              alt=""
            > 陈先生
          </p>
          <p>
            <img
              src="../assets/image/telephone.png"
              alt=""
            >
            18585851923
          </p>
          <p>

            <img
              src="../assets/image/mailbox.png"
              alt=""
            >
            tycycloud@163.com
          </p>
        </div>
        <div class="box">
          <el-amap
            style="width:100%;height:100%;"
            :center='center'
            :zoom='zoom'
          >
            <el-amap-marker
              :position="center"
              :label="label"
            ></el-amap-marker>
          </el-amap>
        </div>

        <div class="box">
          <span class="title">联系地址</span>
          <div class="address">
            <img
              src="../assets/image/tower.png"
              alt=""
            >
            <div class="address_title">
              <a
                href="https://map.baidu.com/search/%E8%B4%B5%E9%98%B3%E7%A7%91%E6%8A%80%E5%A4%A7%E5%8E%A6/@11872789.32326301,3057533.4699999997,19z?querytype=s&da_src=shareurl&wd=%E8%B4%B5%E9%98%B3%E7%A7%91%E6%8A%80%E5%A4%A7%E5%8E%A6&c=146&src=0&wd2=%E8%B4%B5%E9%98%B3%E5%B8%82%E8%A7%82%E5%B1%B1%E6%B9%96%E5%8C%BA&pn=0&sug=1&l=12&b=(11809445.18,3031084.2;11932325.18,3090540.2)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=637df60adfc58b7341afe6bb&device_ratio=1"
                target="_blank"
              >
                <p>贵州省贵阳市国家高新技术产业开发区金阳科技产业园贵阳科技大厦A栋9楼</p>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- 页尾 -->
    <Footer />
  </div>
</template>

<script>

import Footer from '../components/Public/Footer.vue'
export default {
  name: "Contact",
  components: {
    Footer
  },
  data() {
    return {
      center: [106.647848, 26.620303],
      zoom: 20,
      label: {
        content: '贵州拓宇创业科技有限公司',
        offset: [19, 0]
      }
    }
  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style lang="scss">
#contact {
  padding-top: 60px;
  height: auto;
  .contact_content {
    img {
      width: 100vw;
      height: auto;
    }
    .contact_box {
      width: 1160px;
      height: 500px;
      margin: auto;
      // padding: 100px 0;
      position: relative;
      top: -260px;
      display: grid;
      grid-template-columns: 2fr 4fr;
      grid-template-rows: 1fr 1fr;
      .box {
        padding: 20px 5%;
        background-color: white;
        .title {
          font-size: 36px;
          font-family: PingFang SC, PingFang SC-Regular;
          color: black;
        }
        p {
          margin: 20px 0;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          img {
            width: auto;
            margin-right: 10px;
          }
        }
        .address {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-end;
          margin-top: 15px;
          img {
            width: auto;
          }
          .address_title {
            margin-left: 10px;
            a {
              font-size: 18px;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: 400;
              color: #343434;
              p {
                margin: 0;
                line-height: 30px;
              }
            }
          }
        }
        &:nth-child(2) {
          grid-row-start: 1;
          grid-row-end: 3;
          grid-column-start: 2;
          grid-column-end: 3;
          padding: 0;
        }
        &:nth-child(3) {
          background-color: #f6f7f8;
        }
      }
    }
    .address_box {
      width: 1160px;
      height: auto;
      margin: auto;
      h1 {
        font-size: 32px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: black;
      }
      .address {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        img {
          width: auto;
        }
        .address_title {
          margin-left: 10px;
          a {
            line-height: 35px;
            font-size: 18px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            color: #343434;
          }
        }
      }
    }
  }
}
@media (max-width: 1160px) {
  #contact {
    padding-top: 60px;
    height: auto;
    .contact_content {
      img {
        width: 100vw;
        height: auto;
      }
      .contact_box {
        width: 80vw;
        height: 500px;
        margin: auto;
        top: -17vh;
        display: grid;
        grid-template-columns: 2fr 4fr;
        grid-template-rows: 1fr 1fr;
        .box {
          padding: 20px 5%;
          background-color: white;
          .title {
            font-size: 36px;
            font-family: PingFang SC, PingFang SC-Regular;
            color: black;
          }
          p {
            margin: 20px 0;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            img {
              width: auto;
              margin-right: 10px;
            }
          }

          .address {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-end;
            margin-top: 15px;
            img {
              width: auto;
            }
            .address_title {
              margin-left: 10px;
              a {
                font-size: 18px;
                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                font-weight: 400;
                color: #343434;
                p {
                  margin: 0;
                }
              }
            }
          }
          &:nth-child(2) {
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column-start: 2;
            grid-column-end: 3;
            padding: 0;
          }
          &:nth-child(2n) {
            background-color: #f6f7f8;
          }
        }
      }
      .address_box {
        width: 80vw;
        height: auto;
        margin: auto;
        h1 {
          font-size: 32px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: black;
        }
        .address {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-end;
          img {
            width: auto;
          }
          .address_title {
            margin-left: 10px;
            a {
              line-height: 35px;
              font-size: 18px;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: 400;
              color: #343434;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 850px) {
  #contact {
    padding-top: 60px;
    height: auto;
    .contact_content {
      img {
        width: 100vw;
        height: auto;
      }
      .contact_box {
        width: 80vw;
        height: 750px;
        margin: auto;
        top: -10vh;
        display: grid;
        grid-template-columns: 2fr 2fr;
        grid-template-rows: 0.5fr 1fr;
        .box {
          padding: 20px 5%;
          background-color: white;
          .title {
            font-size: 36px;
            font-family: PingFang SC, PingFang SC-Regular;
            color: black;
          }
          p {
            margin: 20px 0;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            img {
              width: auto;
              margin-right: 10px;
            }
          }

          .address {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-end;
            margin-top: 15px;
            img {
              width: auto;
            }
            .address_title {
              margin-left: 10px;
              a {
                font-size: 18px;
                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                font-weight: 400;
                color: #343434;
                p {
                  margin: 0;
                }
              }
            }
          }
          &:nth-child(2) {
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 3;
            padding: 0;
          }
          &:nth-child(2n) {
            background-color: #f6f7f8;
          }
        }
      }
      .address_box {
        width: 80vw;
        height: auto;
        margin: auto;
        h1 {
          font-size: 32px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: black;
        }
        .address {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-end;
          img {
            width: auto;
          }
          .address_title {
            margin-left: 10px;
            a {
              line-height: 35px;
              font-size: 18px;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: 400;
              color: #343434;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 425px) {
  #contact {
    padding-top: 60px;
    height: auto;
    .contact_content {
      img {
        width: 100vw;
        height: auto;
      }
      .contact_box {
        width: 80vw;
        height: auto;
        margin: auto;
        top: -3vh;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 3fr;
        .box {
          padding: 20px 5%;
          background-color: #f6f7f8;
          .title {
            font-size: 36px;
            font-family: PingFang SC, PingFang SC-Regular;
            color: black;
          }
          p {
            margin: 20px 0;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            img {
              width: auto;
              margin-right: 10px;
            }
          }

          .address {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-end;
            margin-top: 15px;
            img {
              width: auto;
            }
            .address_title {
              margin-left: 10px;
              a {
                font-size: 18px;
                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                font-weight: 400;
                color: #343434;
                p {
                  margin: 0;
                }
              }
            }
          }
          &:nth-child(1) {
            grid-row-start: 1;
            grid-row-end: 2;
          }
          &:nth-child(2) {
            grid-row-start: 3;
            grid-row-end: 3;
            padding: 0;
          }
          &:nth-child(3) {
            grid-row-start: 2;
            grid-row-end: 3;
          }
          &:nth-child(1) {
            background-color: white;
          }
        }
      }
      .address_box {
        width: 80vw;
        height: auto;
        margin: auto;
        h1 {
          font-size: 32px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: black;
        }
        .address {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-end;
          img {
            width: auto;
          }
          .address_title {
            margin-left: 10px;
            a {
              line-height: 35px;
              font-size: 18px;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: 400;
              color: #343434;
            }
          }
        }
      }
    }
  }
}
</style>